export function focusElement(element: HTMLElement): void {
    /**
     * Focus on a given html element.
     *
     * Note: This function relies on the `document` object.
     * In case if we migrate to SSR approach in the future, we will need to review the logic here.
     */
    if (document?.activeElement === element) {
        return;
    }

    if (!element.hasAttribute('tabIndex')) {
        element.setAttribute('tabIndex', '-1');
        element.addEventListener(
            'blur',
            (e) => {
                e.preventDefault();
                element.removeAttribute('tabIndex');
            },
            { once: true },
        );
    }

    element.focus();
}

export function focusLayoutDiv() {
    /**
     * Focus on the layout div container, which is generated by `Layout` component.
     *
     * Note: This function relies on the `document` object.
     * In case if we migrate to SSR approach in the future, we will need to review the logic here.
     */
    const layoutDiv = document?.querySelector('div#layout');
    if (layoutDiv) {
        focusElement(layoutDiv as HTMLDivElement);
    }
}
