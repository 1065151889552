export enum DOWNLOAD_STAGE {
    INITIAL = 'INITIAL',
    PENDING = 'PENDING',
    REFRESH = 'REFRESH',

    SUCCEEDED = 'SUCCEEDED',
    UPLOADING = 'UPLOADING',
    FAILED = 'FAILED',
    TIMEOUT = 'TIMEOUT',
    NO_RECORDS = 'NO_RECORDS',
}
